import styled from "@emotion/styled";
import { EarningsResponse } from "../../api";
import { Colors } from "../../component/Color";
import { Space } from "../../component/space";
import Typography from "../../component/Typography";
import { DateTimeUtils } from "../../util/time";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0 12px 0;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid ${Colors.gray10};
  border-radius: 40px;
  overflow: hidden;
  box-sizing: border-box;
`;

const Image = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TimeText = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.gray80};
`;

export default function EarningsItem({ earning }: { earning: EarningsResponse }) {
  return (
    <Container>
      <ImageContainer>
        <Image src={earning.tickerIconUrl} alt={earning.ticker} />
      </ImageContainer>
      <Space width={6} />

      <TimeText>
        <Typography variant="5/semibold" color={Colors.black}>
          {earning.ticker}
        </Typography>
        <Typography variant="3/regular" color={Colors.gray60}>
          {DateTimeUtils.formatTimeSimpleShort(earning.eventAt)}
        </Typography>
      </TimeText>
    </Container>
  );
}
