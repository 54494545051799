import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./index.css";
import { Container } from "./pages/container";
import EarningsCalendar from "./pages/earnings-calendar";
import EarningsCall from "./pages/earnings-call";
import GoStore from "./pages/gostore";
import Home from "./pages/home";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
  {
    path: "*",
    element: <Home />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/go-store",
    element: <GoStore />,
  },
  {
    path: "/earnings-calls",
    element: <EarningsCall />,
  },
  {
    path: "/earnings-calls/:earningsCallId",
    element: <EarningsCall />,
  },
  {
    path: "/earnings-calls/:ticker/:earningsCallId",
    element: <EarningsCall />,
  },
  {
    path: "/earnings-calendar",
    element: <EarningsCalendar />,
  },
]);

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Container>
        <RouterProvider router={router} />
      </Container>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
