import styled from "@emotion/styled";
import { Colors } from "../Color";
import Typography from "../Typography";
import { ButtonProps } from "./props";
import { CSSProperties } from "react";

const Container = styled.div<{ selected: boolean; border?: boolean; style?: CSSProperties; bgColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 52px;
  max-width: 170px;
  padding: 0 20px 0 18px;
  gap: 8px;
  border-radius: 10px;
  background-color: ${({ selected, bgColor }) => (selected ? Colors.gray10 : bgColor ?? Colors.black)};
  border: ${({ border }) => (border ? "1px solid #262626" : "none")};
  cursor: pointer;
`;

export default function LargeButton(props: ButtonProps) {
  const onClick = () => {
    if (!props.disabled) {
      props.onClick && props.onClick();
    }
  };

  const icon = props.disabled ? props.disabledIcon : props.selected && props.selectedIcon ? props.selectedIcon : props.icon;
  const color = props.color ? props.color : props.selected ? Colors.black : Colors.white;
  return (
    <Container onClick={onClick} selected={props.selected || false} style={props.style} bgColor={props.bgColor}>
      {icon && <img src={icon} alt="icon" />}
      {props.text && <Typography variant="8/semibold" text={props.text} color={color} />}
    </Container>
  );
}
