import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { EarningsResponse } from "../../api";
import { Colors } from "../../component/Color";
import Typography from "../../component/Typography";
import EarningsItem from "./EarningsItem";
import { Space } from "../../component/space";

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${Colors.white};
  border-radius: 16px;

  position: relative;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
`;

const Flex = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const MarketItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px 8px 0px;
  border-bottom: 1px solid ${Colors.gray20};
`;

const EarningsListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 16px 0 8px 0;
`;

const Separator = styled.div`
  position: absolute;
  left: 50%;
  top: 62px;
  bottom: 0;
  width: 1px;
  background-color: ${Colors.gray10};
`;

const EmptyContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 62px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 0 0;
`;

const ITEMS_PER_LINE = 13;
export default function EarningsDateList({ date, earningsList }: { date: DateTime; earningsList: EarningsResponse[] }) {
  const targetDate = date.toLocal();
  const firstLine = earningsList
    .filter((earning) => earning.eventAt.toFormat("yyyy-MM-dd") === targetDate.toFormat("yyyy-MM-dd"))
    .slice(0, ITEMS_PER_LINE)
    .sort((a, b) => a.eventAt.diff(b.eventAt).toMillis());
  const secondLine = earningsList
    .filter((earning) => earning.eventAt.toFormat("yyyy-MM-dd") !== targetDate.toFormat("yyyy-MM-dd"))
    .slice(0, ITEMS_PER_LINE)
    .sort((a, b) => a.eventAt.diff(b.eventAt).toMillis());
  return (
    <Container>
      <Body>
        <MarketItem>
          <Flex>
            <Typography variant="7.5/semibold" color={Colors.black}>
              장전
            </Typography>
            <Typography variant="4/regular" color={Colors.gray60}>
              {targetDate.toFormat("M월 d일")}
            </Typography>
          </Flex>
          <Flex>
            <Typography variant="7/semibold" color={Colors.black}>
              장후
            </Typography>
            <Typography variant="4/regular" color={Colors.gray60}>
              {targetDate.plus({ days: 1 }).toFormat("M월 d일")}
            </Typography>
          </Flex>
        </MarketItem>
        <EarningsListContainer>
          <Flex>
            {firstLine.map((earning) => (
              <EarningsItem earning={earning} key={earning.id} />
            ))}
          </Flex>
          <Flex>
            {secondLine.map((earning) => (
              <EarningsItem earning={earning} key={earning.id} />
            ))}
          </Flex>
        </EarningsListContainer>
      </Body>
      {earningsList.length > 0 ? (
        <Separator />
      ) : (
        <EmptyContainer>
          <Typography variant="5/regular" color={Colors.gray40}>
            발표 일정이 없습니다.
          </Typography>
        </EmptyContainer>
      )}
    </Container>
  );
}
