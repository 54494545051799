import styled from "@emotion/styled";

import TopHeader, { Tab } from "../../component/top-header";
import { MOBILE_BREAKPOINT_2, useIsMobile } from "../../util/mediaquery";
import CalendarBody from "./CalendarBody";
import MobileEarningsCalendar from "./MobileEarningsCalendar";

interface Props {
  earningsId?: number;
}

const Container = styled.div`
  overflow: hidden;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  margin: 86px 20px 0 20px;

  @media (max-width: ${MOBILE_BREAKPOINT_2}px) {
    margin: 148px 20px 0 20px;
  }
`;

export default function EarningsCalendar(props: Props) {
  const isMobile = useIsMobile(2);

  if (isMobile) {
    return (
      <Container>
        <TopHeader selectedTab={Tab.EarningsCalendar} />
        <Layout>
          <MobileEarningsCalendar />
        </Layout>
      </Container>
    );
  } else {
    return (
      <Container>
        <TopHeader selectedTab={Tab.EarningsCalendar} showBorder />
        <Layout>
          <CalendarBody />
        </Layout>
      </Container>
    );
  }
}
