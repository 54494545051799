// create react component
import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EarningsConferenceCall, EarningsResponse, Ticker } from "../../api";
import { fetchEarnings, fetchEarningsCalls, fetchConferenceCalls } from "../../api/quries/earningEvent";
import BottomNavigation from "../../component/bottom-navigation";
import TopHeader, { Tab } from "../../component/top-header";
import TickerItem from "../../component/ticker-item";
import EarningsCall from "../earnings-call";
import EarningsCallTranscript from "../earnings-call/EarningsCallTranscriptPlayer";
import Typography from "../../component/Typography";
import MediumButton from "../../component/buttons/MediumButton";
import SmallButton from "../../component/buttons/SmallButton";

import IconSearch from "./images/search.svg";
import IconDateArrow from "./images/date_arrow.svg";
import { Colors } from "../../component/Color";

import Search from "../search";
import { useDraggable } from "react-use-draggable-scroll";
import { easeOutQuart } from "../../component/transition";

interface Props {
  earningsId?: number;
}

const Container = styled.div`
  position: relative;
  padding: 0px;
  width: 400px;
  border-right: 1px solid ${Colors.gray100};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 20px 20px 20px;
  overflow-x: scroll;

  border-bottom: 1px solid ${Colors.gray100};

  -ms-overflow-style: none; // IE와 Edge
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DateItem = styled.div<{ active?: boolean }>`
  min-width: 70px;
  width: 70px;
  height: 83px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  background-color: ${(props) => (props.active ? Colors.gray90 : Colors.black)};
  cursor: pointer;
`;

const DateText = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 30.8px;
  text-align: left;
`;

const DateArea = styled.div`
  position: relative;
  &:hover {
    > .date-mover {
      opacity: 1;
    }
  }
`;

const DateMover = styled.div<{ left?: boolean }>`
  position: absolute;
  top: 0;
  left: ${(props) => (props.left ? 0 : "auto")};
  right: ${(props) => (props.left ? "auto" : 0)};

  display: flex;
  align-items: center;
  justify-content: center;

  width: 42px;
  height: 83px;

  opacity: 0;

  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  transform: ${(props) => (!props.left ? "rotate(180deg)" : "none")};

  transition: opacity 0.4s ${easeOutQuart};
  cursor: pointer;

  > img {
    transition: transform 0.4s ${easeOutQuart};
    &:hover {
      transform: scale(1.2);
    }
  }
`;

const SearchBarRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const EarningsList = styled.div`
  height: calc(100vh - 300px);
  overflow-y: scroll;

  -ms-overflow-style: none; // IE와 Edge
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SearchBarRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const DAYS = 28;
const WEEKDAYS = ["월", "화", "수", "목", "금", "토", "일"];
export default function EarningsCallCalendar(props: Props) {
  const navigate = useNavigate();
  const { earningsCallId } = useParams();
  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.local().startOf("day"));
  const [selectedEvents, setSelelectedEvents] = useState<EarningsConferenceCall>();
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  const { data: earningEvents, isFetching } = useQuery({
    queryKey: ["earningEvents", selectedDate.toISO()],
    queryFn: () => fetchConferenceCalls(selectedDate, selectedDate.endOf("day")),
  });

  function openSearch() {
    setSearchOpen(true);
  }

  // redraw item when earningsId changes
  useEffect(() => {
    setSelelectedEvents(earningEvents?.find((item) => item.id === Number(earningsCallId)));
  }, [earningEvents, earningsCallId]);

  const now = DateTime.local().startOf("day");
  const dateMenus = Array.from({ length: DAYS }, (_, i) => {
    const date = now.plus({ day: -(DAYS / 2) + i });
    return {
      dateText: `${date.day}`,
      label: WEEKDAYS[date.weekday - 1],
      date: date,
    };
  });

  const onClickEvent = (ticker: string, id: number) => {
    navigate(`/earnings-calls/${ticker}/${id}`);
  };

  const scrollToToday = () => {
    const elemes = window.document.getElementById(`calendar-${DateTime.local().startOf("day").toFormat("yy-MM-dd")}`);
    elemes?.scrollIntoView({ inline: "center" });
  };
  useEffect(() => {
    scrollToToday();
  }, []);

  const scrollToLeft = () => {
    ref.current?.scrollTo({ left: ref.current.scrollLeft - 300, behavior: "smooth" });
  };

  const scrollToRight = () => {
    ref.current?.scrollTo({ left: ref.current.scrollLeft + 300, behavior: "smooth" });
  };

  const moveToToday = () => {
    setSelectedDate(DateTime.local().startOf("day"));
    scrollToToday();
  };

  const isToday = selectedDate.toMillis() === DateTime.local().startOf("day").toMillis();

  return (
    <Container>
      <SearchBarRow>
        <Typography variant="12/bold">어닝콜</Typography>
        <SearchBarRightContainer>
          <MediumButton selected={isToday} onClick={moveToToday} text="오늘" border />
          <MediumButton icon={IconSearch} onClick={openSearch} border circle />
        </SearchBarRightContainer>
      </SearchBarRow>
      <DateArea>
        <DateContainer ref={ref} {...events}>
          {dateMenus.map((item, index) => {
            const active = selectedDate.toMillis() === item.date.toMillis();
            return (
              <DateItem
                id={`calendar-${item.date.toFormat("yy-MM-dd")}`}
                key={item.dateText}
                active={active}
                onClick={() => setSelectedDate(item.date)}
              >
                <DateText>
                  <Typography variant="12/bold" color={active ? Colors.white : Colors.gray40}>
                    {item.dateText}
                  </Typography>
                </DateText>
                <Typography variant="4/regular" color={active ? Colors.gray30 : Colors.gray40}>
                  {" "}
                  {item.label}
                </Typography>
              </DateItem>
            );
          })}
        </DateContainer>
        <DateMover left className="date-mover" onClick={scrollToLeft}>
          <img src={IconDateArrow} alt="date-arrow" />
        </DateMover>
        <DateMover className="date-mover" onClick={scrollToRight}>
          <img src={IconDateArrow} alt="date-arrow" />
        </DateMover>
      </DateArea>
      <EarningsList>
        {isFetching ? (
          <LoadingContainer>
            <div>Loading...</div>
          </LoadingContainer>
        ) : (
          <>
            {earningEvents?.map((item) => {
              return (
                <TickerItem
                  hover
                  key={item.id}
                  earnings={item}
                  onClick={() => onClickEvent(item.ticker, item.id)}
                  selected={item.id === selectedEvents?.id}
                />
              );
            })}
            {!isFetching && earningEvents?.length === 0 && <LoadingContainer>일정이 없습니다.</LoadingContainer>}
          </>
        )}
      </EarningsList>
      <Search onClose={() => setSearchOpen(false)} isOpen={searchOpen} />
    </Container>
  );
}
