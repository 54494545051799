// create react component
import styled from "@emotion/styled";
import { Ticker } from "../../api";
import TopHeader, { Tab } from "../../component/top-header";
import Feature from "./Feature";
import LargeButton from "../../component/buttons/LargeButton";
import IcAppStore from "./images/appstore.svg";
import LogoWithText from "./images/logo_with_text.svg";
import IcPlayStore from "./images/playstore.svg";

import ImgEarningsCall from "./images/earnings_call.png";

import ImgBranding1 from "./images/branding_image_1.png";

import ImgFeatureEarningsCall from "./images/feature_earnings_call.png";
import ImgFeatureEarnings from "./images/feature_earnings_detail.png";
import ImgFeatureWatchlist from "./images/feature_watchlist.png";

import Typography from "../../component/Typography";
import CompanyInfo from "./CompanyInfo";
import ImgStocknowLogo from "./images/stocknow_logo.svg";

import { AppStoreUtil } from "../../util/appstore";

interface Props {
  ticker?: Ticker;
}

const Container = styled.div`
  padding: 10px 0 50 0px;
`;

const BrandingMessage = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 162px;

  > img {
    width: 187px;
    height: 38px;
  }
`;

const BrandingMessageText = styled.div`
  margin-top: 40px;
`;

const AppDownloadContainer = styled.div`
  margin-top: 50px;

  div + div {
    margin-top: 20px;
  }
  > div {
    width: 170px;
  }
`;

const Images = styled.div`
  position: relative;
  margin-top: 45px;
  height: 520px;
  overflow: hidden;
`;

const Bg = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.09) 0%, #000000 100%);

  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 185px;
`;

const FeatureContainer = styled.div`
  padding: 0 20px;
`;

const BottomBrandingMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  padding-bottom: 50px;
`;

const BottomBrandingMessageIcon = styled.div`
  border: 1px solid #262626;

  padding: 19.6px 18.05px 19.6px 21.14px;
  border-radius: 32px;
`;

const BottomBrandingMessageText = styled.div`
  text-align: center;
  margin-top: 40px;
`;

export default function MobileHome(props: Props) {
  return (
    <Container>
      <TopHeader selectedTab={Tab.Intro} />
      <BrandingMessage>
        <div>
          <img src={LogoWithText} alt="" />
        </div>

        <Typography variant="16/bold" textAlign="center" component="div" style={{ marginTop: "40px" }}>
          미국 주식 어닝콜을
          <br /> 실시간 번역과
          <br /> 라이브로.
        </Typography>

        <Images>
          <img src={ImgBranding1} alt="branding_1" width={335} />
          <Bg />
        </Images>
      </BrandingMessage>

      <FeatureContainer>
        <Feature featureName="실시간 번역 & Ai 요약" image={ImgEarningsCall} imageWidth={335} imageAlign={"bottom"} textVariant="16/bold">
          실시간 번역과 Ai 요약으로 가장 빠르게 투자 인사이트를 얻으세요.
        </Feature>

        <Feature featureName="실적 캘린더" image={ImgFeatureEarningsCall} imageWidth={335} imageAlign={"bottom"} textVariant="16/bold">
          글로벌 기업의 실적 발표, 한국 시간으로 쉽게 체크하세요.
        </Feature>

        <Feature featureName="실적 데이터" image={ImgFeatureEarnings} imageWidth={335} imageAlign={"bottom"} textVariant="16/bold">
          최근 실적을 쉽게 비교하고 다가올 발표 일정 놓치지 마세요.
        </Feature>

        <Feature featureName="실적 발표 리마인더" image={ImgFeatureWatchlist} imageWidth={335} imageAlign={"bottom"} textVariant="16/bold">
          관심 종목의 실적 발표 리마인더로 정보 선점 기회를 잡으세요.
        </Feature>
      </FeatureContainer>

      <BottomBrandingMessage>
        <BottomBrandingMessageIcon>
          <img src={ImgStocknowLogo} alt="App Icon" />
        </BottomBrandingMessageIcon>
        <BottomBrandingMessageText>
          <Typography variant="16/bold" textAlign="center">
            실시간 <br />
            미국 주식 정보는 <br />
            스톡나우에서.
          </Typography>
        </BottomBrandingMessageText>

        <AppDownloadContainer style={{ marginTop: "50px" }}>
          <LargeButton selected icon={IcPlayStore} text="GooglePlay" onClick={() => window.open(AppStoreUtil.playStoreUrl, "_blank")}>
            시작하기
          </LargeButton>
          <LargeButton selected icon={IcAppStore} text="AppStore" onClick={() => window.open(AppStoreUtil.appStoreUrl, "_blank")}>
            시작하기
          </LargeButton>
        </AppDownloadContainer>
      </BottomBrandingMessage>

      <CompanyInfo />
    </Container>
  );
}
