import { DateTime } from "luxon";

export interface Ticker {
  iconUrl?: string;
  ticker: string;
  name: string;
  displayName: string;
  primaryExchange: string;
  sicDescription: string;
  marketCap: number;
  extraInfo?: string;
}

export interface TickerStatsResponse {
  ticker: string;
  stats: TickerStats[];
}

export interface TickerStats {
  aggregateType: string;
  et: number;
  volume: number;
  volumeWeightedAveragePrice: number;
  openPrice: number;
  closePrice: number;
  highPrice: number;
  lowPrice: number;
  averageTradeSize: number;
  todayAccumulatedVolume: number;
  todayOfficialOpenPrice: number;
  todayAveragePrice: number;
}

export interface LastTrade {
  ticker: string;
  price: number;
  timestamp: string;
}

export interface MarketStatus {
  market: "open" | "closed" | "pre-market" | "after-hours";
  serverTime: string;

  latestMarketTimes: MarketOpenTime[];
  nextMarketTimes: MarketOpenTime[];

  holidays: Holiday[];

  chartTimeRange: { from: DateTime; to: DateTime };
}

export interface Holiday {
  date: string;
  name: string;
}

export interface MarketOpenTime {
  date: string;

  regularMarketStart: string;
  regularMarketEnd: string;
  afterHoursStart: string;
  afterHoursEnd: string;
  preMarketStart: string;
  preMarketEnd: string;

  // converted
  regularMarketStartDateTime: DateTime;
  regularMarketEndDateTime: DateTime;
  afterHoursStartDateTime: DateTime;
  afterHoursEndDateTime: DateTime;
  preMarketStartDateTime: DateTime;
  preMarketEndDateTime: DateTime;
}

export interface EarningsResponse {
  id: number;
  ticker: string;
  eventAt: DateTime;
  period: string;
  periodYear: number;

  marketCap: number;
  companyName: string;
  tickerIconUrl?: string;
  priceChangeRate: number;

  eps?: number;
  epsEst?: number;
  epsPrior?: number;
  epsSurprise?: number;
  epsSurprisePercent?: number;
  revenue?: number;
  revenueEst?: number;
  revenuePrior?: number;
  revenueSurprise?: number;
  revenueSurprisePercent?: number;

  createdAt: DateTime;
  updatedAt: DateTime;

  earningsConferenceCallsId?: number;
  isQna?: boolean;

  liveAudioState?: string;
  liveEventAt?: DateTime;
  audioUrl?: string;
  transcriptUrl?: string;
}

export const CONF_EVENT_TYPE_EARNINGS_CALL = "Earnings Call";

export interface EarningsConferenceCall {
  id: number;
  eventTitle: string;
  eventType: string;
  ticker: string;
  eventAt: DateTime;
  period: string;
  periodYear: number;

  slidesUrl?: string;
  reportUrl?: string;

  audioUrl?: string;
  audioDuration?: number;
  qnaTimestamp?: number;

  liveAudioState?: string;

  liveTranscriptUrl?: string;
  koLiveTranscriptUrl?: string;

  summary?: EarningsConferenceCallSummary;

  companyName: string;
  tickerIconUrl?: string;

  isQna: boolean;
}

export interface EarningsConferenceCallSummary {
  summary?: string[];
  outlook?: string[];
  guidance?: string[];
  executiveComment?: string[];
  qna?: string[];
}

export interface EarningsDetailResponse {
  id: number;
  ticker: string;
  eventAt: DateTime;
  period: string;
  periodYear: number;

  marketCap: number;
  companyName: string;
  tickerIconUrl?: string;
  priceChangeRate: number;

  eps?: number;
  epsEst?: number;
  epsPrior?: number;
  epsSurprise?: number;
  epsSurprisePercent?: number;
  revenue?: number;
  revenueEst?: number;
  revenuePrior?: number;
  revenueSurprise?: number;
  revenueSurprisePercent?: number;

  createdAt: DateTime;
  updatedAt: DateTime;

  earningsConferenceCalls: EarningsConferenceCall[];
}

export interface EarningsCallTranscript {
  type?: string;
  s?: number; // start
  e?: number; // end
  p?: string; // paragraph identifier
  S?: string; // speaker identifier
  t?: string; // transcript text
  ot?: string; // optional text
  tr?: string; // translated text
  processing_start_time?: string;
  processing_end_time?: string;
  audio_url?: string;
  file_format_version?: string;
  system_reason?: string;
  user_reason?: string;
  code?: number;
}

export interface TickerAndEarningsResponse {
  ticker: Ticker;
  earnings: EarningsDetailResponse[];
}
