// create react component
import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EarningsResponse } from "../../api";
import { fetchEarnings } from "../../api/quries/earningEvent";
import { Colors } from "../../component/Color";
import Typography from "../../component/Typography";
import { Row } from "../../component/row";
import { Space } from "../../component/space";
import { copyElementToPNG } from "../../util/clipboard";
import CalendarHeaderItem from "./CalendarHeaderItem";
import EarningsDateList from "./EarningsDateList";
import EarningsDateListHeader from "./EarningsDateListHeader";
import FloatingContainer, { Week } from "./FloatingContainer";
import KoreanTime from "./KoreanTime";
import logo from "./images/logo_with_text_gray.png";
import { easeOutQuart } from "../../component/transition";

interface Props {}

const Container = styled.div`
  width: 100%;
`;

const CaptureContainer = styled.div`
  width: 100%;
  background-color: ${Colors.black};

  opacity: 0;
  transition: opacity 1s ${easeOutQuart};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  position: relative;

  background-color: ${Colors.gray100};
  border-radius: 30px 30px 0 0;
`;

const EarningsHeaderList = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;

  padding: 0 20px;
  background-color: ${Colors.gray100};
  gap: 10px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const GrayContainer = styled.div`
  background-color: ${Colors.gray20};
  border-radius: 0 0 32px 32px;

  padding: 10px 10px 20px 10px;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;

  min-height: 600px;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 30px;
  background-color: ${Colors.gray80};
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// group by earnings by dates
const groupByDate = (earnings: EarningsResponse[]) => {
  return earnings.reduce((acc, earning) => {
    const date = earning.eventAt.setZone("America/New_York").startOf("day").toFormat("yyyy-MM-dd");
    acc[date] = acc[date] || [];
    acc[date].push(earning);
    return acc;
  }, {} as Record<string, EarningsResponse[]>);
};

export const fetchGroupedEarnings = async (from: DateTime, to: DateTime): Promise<Record<string, EarningsResponse[]>> => {
  const earnings = await fetchEarnings(from, to);
  const groups = groupByDate(earnings);
  for (const date in groups) {
    var original = groups[date];
    original.sort((a, b) => b.marketCap - a.marketCap);
  }
  return groups;
};

export default function MobileEarningsCalendar(props: Props) {
  const elementRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const week = (searchParams.get("week") ?? "current") as Week;

  // start of week
  const targetDate = DateTime.now().setZone("America/New_York");
  const startOfWeek = targetDate.startOf("week").plus({ week: week === "prev" ? -1 : week === "next" ? 1 : 0 });

  const dates = [
    startOfWeek,
    startOfWeek.plus({ days: 1 }),
    startOfWeek.plus({ days: 2 }),
    startOfWeek.plus({ days: 3 }),
    startOfWeek.plus({ days: 4 }),
  ];

  const [translationToastShown, setTranslationToastShown] = useState(false);
  const [translationToastText, setTranslationToastText] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<DateTime>(
    week === "current" ? DateTime.now().setZone("America/New_York").startOf("day") : startOfWeek
  );

  console.log("selectedDate", selectedDate.toFormat("yyyy-MM-dd"));

  useEffect(() => {
    setSelectedDate(week === "current" ? DateTime.now().setZone("America/New_York").startOf("day") : startOfWeek);
  }, [week]);

  const endOfWeek = startOfWeek.endOf("week");
  const {
    data: earningsMap,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["fetchGroupedEarnings", startOfWeek, endOfWeek],
    queryFn: () => fetchGroupedEarnings(startOfWeek, endOfWeek),
  });

  const handleWeekChange = (week: Week) => {
    navigate(`/earnings-calendar?week=${week}`);
    // scroll to top
    window.scrollTo(0, 0);
  };

  const showToast = (text: string) => {
    setTranslationToastShown(true);
    setTranslationToastText(text);
    setTimeout(() => {
      setTranslationToastShown(false);
    }, 3000);
  };

  const handleShare = () => {
    // copy url to clipboard
    if (navigator.canShare()) {
      navigator.share({
        title: "미국 주식 실적 발표 일정",
        text: "미국 주식 실적 발표 일정",
        url: window.location.href,
      });
    } else {
      navigator.clipboard.writeText(window.location.href);
      showToast("URL이 클립보드에 복사되었습니다.");
    }
  };

  const handleCapture = async () => {
    if (elementRef.current) {
      await copyElementToPNG(elementRef.current);
      showToast("이미지가 클립보드에 복사되었습니다.");
    }
  };

  const selectedEarningsMap = earningsMap?.[selectedDate.toFormat("yyyy-MM-dd")];

  return (
    <Container>
      <CaptureContainer ref={elementRef} style={{ opacity: earningsMap ? 1 : 0 }}>
        <HeaderContainer>
          <Space height={30} />
          <KoreanTime />
          <Space height={10} />
          <Typography variant="14/bold" color={Colors.gray20} textAlign="center">
            미국 주식 <br />
            실적 발표 일정
          </Typography>
          <Space height={15} />
          {earningsMap ? (
            <Row style={{ overflow: "scroll" }}>
              <CalendarHeaderItem date={dates[0]} />
              <Space width={10} />
              <Dot />
              <Space width={4} />
              <Dot />
              <Space width={4} />
              <Dot />
              <Space width={10} />
              <CalendarHeaderItem date={dates[dates.length - 1]} />
            </Row>
          ) : (
            <></>
          )}
          <Space height={20} />
        </HeaderContainer>
        {earningsMap ? (
          <>
            <EarningsHeaderList>
              {dates.map((date) => (
                <EarningsDateListHeader
                  date={date}
                  key={date.toFormat("yyyy-MM-dd")}
                  selected={date.toFormat("yyyy-MM-dd") === selectedDate.toFormat("yyyy-MM-dd")}
                  onClick={() => setSelectedDate(date)}
                />
              ))}
            </EarningsHeaderList>
            <GrayContainer>
              <CalendarContainer>
                <EarningsDateList key={selectedDate.toFormat("yyyy-MM-dd")} date={selectedDate} earningsList={selectedEarningsMap ?? []} />
              </CalendarContainer>
              <Space height={20} />
              <LogoContainer>
                <img src={logo} width={110} alt="logo" />
              </LogoContainer>
            </GrayContainer>
          </>
        ) : (
          <></>
        )}
        <Space height={120} />
      </CaptureContainer>

      <FloatingContainer selectedWeek={week} onWeekChange={handleWeekChange} onShare={handleShare} onCapture={handleCapture} />

      <TranslationToastContainer style={{ opacity: translationToastShown ? 1 : 0 }}>
        <Typography variant="4/regular" color={Colors.white}>
          {translationToastText}
        </Typography>
      </TranslationToastContainer>
    </Container>
  );
}

const TranslationToastContainer = styled.div`
  position: fixed;
  bottom: 120px;
  right: 10px;

  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 16px;
  background-color: ${Colors.gray90};
  opacity: 0;

  transition: opacity 1s ${easeOutQuart};
  pointer-events: none;

  box-shadow: 0px 10px 20px 0px #00000066;
`;
