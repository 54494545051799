import html2canvas from "html2canvas";

export const copyElementToPNG = async (element: HTMLElement) => {
  try {
    // 1. html2canvas를 사용하여 요소를 캔버스로 변환
    const canvas = await html2canvas(element, {
      useCORS: true,
      allowTaint: true,
    });

    // 2. 캔버스를 blob으로 변환
    const blob = await new Promise<Blob>((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob!);
      }, "image/png");
    });

    // 3. Clipboard API를 사용하여 클립보드에 복사
    await navigator.clipboard.write([
      new ClipboardItem({
        "image/png": blob,
      }),
    ]);
  } catch (error) {
    alert("캡쳐 중 오류가 발생하였습니다. " + error);
  }
};
