import styled from "@emotion/styled";
import { Colors } from "../../component/Color";
import Typography from "../../component/Typography";
import LargeButton from "../../component/buttons/LargeButton";

import iconShare from "./images/ic_share.svg";
import iconCapture from "./images/ic_capture.svg";
import { MOBILE_BREAKPOINT_2, useIsMobile } from "../../util/mediaquery";

const Container = styled.div`
  position: fixed;
  bottom: 55px;
  left: 0;
  width: 100%;
  min-width: 920px;

  @media (max-width: ${MOBILE_BREAKPOINT_2}px) {
    min-width: 100%;
    bottom: 30px;
  }
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${Colors.gray100};
  width: fit-content;
  padding: 6px;
  margin: 0 auto;
  border-radius: 10px;

  box-shadow: 0px 10px 20px 0px #00000066;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 107.7px;
  height: 40px;

  cursor: pointer;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: ${Colors.white};
  }
`;

const RightContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 40px;

  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 12px;

  margin: 0 auto;
`;

interface RadioButtonProps {
  isSelected: boolean;
  onClick: () => void;
  text: string;
}

function RadioButton(props: RadioButtonProps) {
  return (
    <RadioButtonContainer onClick={props.onClick}>
      <Typography
        component="div"
        variant="8/semibold"
        textAlign="center"
        color={props.isSelected ? Colors.black : Colors.gray50}
        style={{ backgroundColor: props.isSelected ? Colors.white : Colors.gray100 }}
      >
        {props.text}
      </Typography>
    </RadioButtonContainer>
  );
}

export type Week = "prev" | "current" | "next";

interface FloatingContainerProps {
  selectedWeek: Week;
  onWeekChange: (week: Week) => void;
  onShare: () => void;
  onCapture: () => void;
}

export default function FloatingContainer(props: FloatingContainerProps) {
  const isMobile = useIsMobile(2);
  const selectedWeek = props.selectedWeek;

  return (
    <Container>
      <RadioButtonsContainer>
        <RadioButton text="지난주" isSelected={selectedWeek === "prev"} onClick={() => props.onWeekChange("prev")} />
        <RadioButton text="이번주" isSelected={selectedWeek === "current"} onClick={() => props.onWeekChange("current")} />
        <RadioButton text="다음주" isSelected={selectedWeek === "next"} onClick={() => props.onWeekChange("next")} />
      </RadioButtonsContainer>
      {!isMobile ? (
        <RightContainer>
          <LargeButton
            text="공유"
            bgColor={Colors.gray100}
            style={{ boxShadow: "0px 10px 20px 0px #2D2D2D66" }}
            icon={iconShare}
            onClick={props.onShare}
          />
          <LargeButton
            text="캡쳐"
            bgColor={Colors.gray100}
            style={{ boxShadow: "0px 10px 20px 0px #2D2D2D66" }}
            icon={iconCapture}
            onClick={props.onCapture}
          />
        </RightContainer>
      ) : (
        <></>
      )}
    </Container>
  );
}
