import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { Colors } from "../../component/Color";
import { Space } from "../../component/space";
import Typography from "../../component/Typography";
import { DateTimeUtils } from "../../util/time";
import { MOBILE_BREAKPOINT_2, useIsMobile } from "../../util/mediaquery";
import { useEffect } from "react";
import { useRef } from "react";

const Header = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${MOBILE_BREAKPOINT_2}px) {
    width: 103px;
    flex: none;
  }
`;

interface EarningsDateListHeaderProps {
  date: DateTime;
  selected?: boolean;
  onClick?: () => void;
}

export default function EarningsDateListHeader({ date, selected = true, onClick }: EarningsDateListHeaderProps) {
  const isMobile = useIsMobile(2);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMobile && selected && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [selected, isMobile]);

  const variant = isMobile ? "7.5/semibold" : "10/semibold";

  return (
    <Header ref={containerRef} onClick={onClick}>
      <Space height={20} />
      <Typography variant={variant} component="div" color={selected ? Colors.gray20 : Colors.gray60}>
        {DateTimeUtils.getWeekDayText(date)}요일 장
      </Typography>
      <Space height={20} />
    </Header>
  );
}
