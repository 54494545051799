import styled from "@emotion/styled";
import { CONF_EVENT_TYPE_EARNINGS_CALL, EarningsConferenceCall, EarningsDetailResponse } from "../../api";
import { Colors } from "../../component/Color";
import { Space } from "../../component/space";
import Typography from "../../component/Typography";

import SmallButton from "../../component/buttons/SmallButton";

import { useState } from "react";
import { DateTimeUtils } from "../../util/time";

import IconPlay from "../../component/ticker-item/images/play.svg";
import IconPlayHover from "../../component/ticker-item/images/play_hover.svg";
import IconPlaySelected from "../../component/ticker-item/images/play_selected.svg";

import { Row } from "../../component/row";
import { getPeriodText } from "../../util/earnings";

interface Props {
  conferenceCall: EarningsConferenceCall;
  onClick?: () => void;
  style?: React.CSSProperties;
  selected?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  padding: 20px;
  cursor: pointer;

  :hover {
    background-color: ${Colors.gray100};
  }
`;

const TickerImgContainer = styled.div`
  position: relative;
`;

const LiveAudioState = styled.div`
  background-color: ${Colors.red50};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  padding: 0 4px;
  border-radius: 5px;
  margin-left: 4px;
`;

const TickerImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #64646466;
`;

const TickerDesc = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const Tags = styled.div`
  flex: 1;
  padding: 0 10px;

  > span {
    background-color: #cecece;
    color: #000;
    font-weight: bold;
    padding: 2px 8px;
    margin: 0 4px 0 0;
    border-radius: 10px;
    font-size: 12px;
  }
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
`;

const TimeWarpper = styled.div`
  display: flex;
  align-items: center;

  height: 33px;
  padding: 0 12px;
  border: 1px solid ${Colors.gray90};
  border-radius: 40px;
`;

const ExpandedContainer = styled.div<{ expanded: boolean }>`
  transition: max-height 0.5s ease-in-out;
  max-height: ${({ expanded }) => (expanded ? "30px" : "0")};
  overflow: hidden;
`;

export default function EarningsItem({ conferenceCall, onClick, style, selected }: Props) {
  const [expanded, setExpanded] = useState(false);

  const transcriptUrl = conferenceCall?.koLiveTranscriptUrl;
  var liveAudioState = conferenceCall?.liveAudioState;
  const isLive = liveAudioState === "live";
  if (!conferenceCall) {
    return <></>;
  }

  const isEarningsCall = conferenceCall.eventType !== CONF_EVENT_TYPE_EARNINGS_CALL ? false : true;

  return (
    <Container onClick={onClick} style={style}>
      <TickerDesc>
        <Row>
          <Typography variant="8/bold" style={{ overflow: "hidden", whiteSpace: "nowrap", maxWidth: "150px" }}>
            {isEarningsCall
              ? `${getPeriodText(conferenceCall.periodYear, conferenceCall.period)} ${conferenceCall.isQna ? "(Q&A)" : ""}`
              : conferenceCall.eventTitle}
          </Typography>
          {isLive && (
            <LiveAudioState>
              <Typography variant="2/medium">LIVE</Typography>
            </LiveAudioState>
          )}
        </Row>
        <Typography variant="6/regular" color={Colors.gray60}>
          {!transcriptUrl
            ? DateTimeUtils.formatYYMM(conferenceCall.eventAt)
            : isLive
            ? DateTimeUtils.formatTimeSimple(conferenceCall.eventAt)
            : DateTimeUtils.formatDateTimeMin(conferenceCall.eventAt)}
        </Typography>
      </TickerDesc>

      <Space />

      <Stats>
        {transcriptUrl ? (
          <SmallButton icon={IconPlay} selectedIcon={IconPlaySelected} hoverIcon={IconPlayHover} circle selected={selected} />
        ) : (
          <TimeWarpper>
            <Typography variant="6/regular" text={DateTimeUtils.formatTimeSimple(conferenceCall.eventAt)} color={Colors.gray40} />
          </TimeWarpper>
        )}
      </Stats>
    </Container>
  );
}
