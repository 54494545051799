import styled from "@emotion/styled";
import { Color, Colors } from "./Color";

export type TextVariants =
  | "1/regular"
  | "1/bold"
  | "2/regular"
  | "2/semibold"
  | "2/medium"
  | "3/semibold"
  | "3/medium"
  | "3/regular"
  | "4/medium"
  | "4/regular"
  | "5/medium"
  | "5/regular"
  | "5/semibold"
  | "6/semibold"
  | "6/regular"
  | "3/bold"
  | "6/bold"
  | "7/medium"
  | "7/semibold"
  | "7.5/regular"
  | "7.5/semibold"
  | "8/semibold"
  | "8/medium"
  | "8/bold"
  | "8/regular"
  | "9/semibold"
  | "9/medium"
  | "10/medium"
  | "10/semibold"
  | "10/bold"
  | "11/semibold"
  | "11/bold"
  | "12/bold"
  | "12/regular"
  | "12/semibold"
  | "13/semibold"
  | "13/regular"
  | "13/bold"
  | "14/semibold"
  | "14/regular"
  | "14/bold"
  | "16/bold"
  | "17/bold"
  | "19/bold"
  | "20/bold";

type Size = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "7.5" | "8" | "9" | "10" | "11" | "14" | "16" | "17" | "19" | "20";
type Weight = "regular" | "medium" | "semibold" | "bold";

const FONT_SIZE_MAP = {
  1: {
    fontSize: "12px",
    lineHeight: "16.8px",
  },
  2: {
    fontSize: "11px",
    lineHeight: "15.4px",
  },
  3: {
    fontSize: "12px",
    lineHeight: "16.8px",
  },
  4: {
    fontSize: "13px",
    lineHeight: "18.2px",
  },
  5: {
    fontSize: "14px",
    lineHeight: "19.6px",
  },
  6: {
    fontSize: "15px",
    lineHeight: "21px",
  },
  7: {
    fontSize: "16px",
    lineHeight: "23.8px",
  },
  7.5: {
    fontSize: "17px",
    lineHeight: "23.8px",
  },
  8: {
    fontSize: "18px",
    lineHeight: "25.2px",
  },
  9: {
    fontSize: "20px",
    lineHeight: "25.2px",
  },
  10: {
    fontSize: "22px",
    lineHeight: "30.8px",
  },
  11: {
    fontSize: "24px",
    lineHeight: "33.6px",
  },
  12: {
    fontSize: "28px",
    lineHeight: "39.2px",
  },
  13: {
    fontSize: "32px",
    lineHeight: "44.8px",
  },
  14: {
    fontSize: "36px",
    lineHeight: "50.4px",
  },
  16: {
    fontSize: "42px",
    lineHeight: "58.8px",
  },
  17: {
    fontSize: "48px",
    lineHeight: "67.2px",
  },
  19: {
    fontSize: "60px",
    lineHeight: "84px",
  },
  20: {
    fontSize: "70px",
    lineHeight: "98px",
  },
};
const FONT_WEIGHT_MAP = {
  regular: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
};

interface Props extends React.HTMLAttributes<HTMLSpanElement | HTMLDivElement> {
  component?: "span" | "div";
  variant?: TextVariants;
  size?: Size;
  weight?: Weight;
  color?: Color | string;
  text?: string;
  textAlign?: "left" | "center" | "right";
  children?: React.ReactNode;
}

const BaseTextSpan = styled.span`
  ::selection {
    background-color: ${Colors.gray70};
  }
`;
const BaseTextDiv = styled.div`
  ::selection {
    background-color: ${Colors.gray70};
  }
`;

export default function Typography(props: Props) {
  function getVariantCss(variant: TextVariants): { size: Size; weight: Weight } {
    const values = variant.split("/");
    return { size: values[0] as Size, weight: values[1] as Weight };
  }

  const css = getVariantCss(props.variant ? props.variant : "1/regular");
  const fontSize = FONT_SIZE_MAP[css.size || props.size || "1"];
  const fontWeight = FONT_WEIGHT_MAP[css.weight || props.weight || "regular"];
  const BaseText = props.component === "div" ? BaseTextDiv : BaseTextSpan;
  const textAlign = props.textAlign || "left";
  return (
    <BaseText {...props} style={{ textAlign, fontWeight, ...fontSize, color: props.color ? props.color : "white", ...props.style }}>
      {props.text}
      {props.children}
    </BaseText>
  );
}
