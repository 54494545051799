import { DateTime } from "luxon";
import { Colors } from "../../component/Color";
import Typography from "../../component/Typography";
import { DateTimeUtils } from "../../util/time";
import styled from "@emotion/styled";
import { MOBILE_BREAKPOINT_2, useIsMobile } from "../../util/mediaquery";

const CalendarHeaderItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;

  @media (max-width: ${MOBILE_BREAKPOINT_2}px) {
    width: 60px;
  }
`;

const Day = styled.div`
  display: Flex;
  width: 100%;
  height: 41px;
  align-items: center;
  justify-content: center;
  border-radius: 12px 12px 0 0;
  background-color: ${Colors.gray90};

  @media (max-width: ${MOBILE_BREAKPOINT_2}px) {
    height: 28px;
  }
`;

const Date = styled.div`
  display: Flex;
  width: 100%;
  height: 69px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.gray20};
  border-radius: 0 0 12px 12px;

  @media (max-width: ${MOBILE_BREAKPOINT_2}px) {
    height: 34px;
  }
`;

interface CalendarHeaderItemProps {
  date: DateTime;
}

export default function CalendarHeaderItem({ date }: CalendarHeaderItemProps) {
  const isMobile = useIsMobile(2);
  if (isMobile) {
    return (
      <CalendarHeaderItemContainer>
        <Day>
          <Typography variant="5/medium" color={Colors.gray30}>
            {DateTimeUtils.getWeekDayText(date)}
          </Typography>
        </Day>
        <Date>
          <Typography variant="7.5/semibold" color={Colors.gray90}>
            {date.toFormat("MM.dd")}
          </Typography>
        </Date>
      </CalendarHeaderItemContainer>
    );
  } else {
    return (
      <CalendarHeaderItemContainer>
        <Day>
          <Typography variant="8/medium" color={Colors.gray30}>
            {DateTimeUtils.getWeekDayText(date)}요일
          </Typography>
        </Day>
        <Date>
          <Typography variant="13/bold" color={Colors.gray90}>
            {date.toFormat("MM.dd")}
          </Typography>
        </Date>
      </CalendarHeaderItemContainer>
    );
  }
}
